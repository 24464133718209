import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  Input,
  Field,
  Control,
  StaticButton,
  Columns,
  Column,
} from "../../../StyleComponents/styles"
import { FlexDiv, Table } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Converter", link: "/units-converter/" },
  {
    name: "Pixel And REM Converter",
    link: "/pixel-and-rem-conversion/",
  },
]
const seeAlsoArray = [
  "/color-converter",
  "/data-storage-converter",
  "/decimal-hexadecimal-binary-converter",
  "/height-unit-converter",
  "/latitude-and-longitude-to-dns-converter",
  "/text-case-converter",
]

function PixelAndREMConverter(props) {
  let [baseElem, setbaseElem] = useState(16)
  let [remElem, setremElem] = useState("")
  let [pxElem, setpxElem] = useState("")

  function baseElemC(e) {
    let value = parseFloat(e.target.value)
    value = isNaN(value) ? "" : value
    setbaseElem(value)
    setremElem("")
    setpxElem("")
  }

  function calculateRem(e) {
    let value = parseFloat(e.target.value)
    if (baseElem === "") {
      setbaseElem("16")
    }

    if (isNaN(value) || value === "") {
      setpxElem("")
      setremElem("")
    } else {
      setremElem(value)
      setpxElem(baseElem * value)
    }
  }

  function calculatePx(e) {
    let value = e.target.value
    if (baseElem === "") {
      setbaseElem("16")
    }
    if (isNaN(value) || value === "") {
      setpxElem("")
      setremElem("")
    } else {
      setpxElem(value)
      let result = (1 / baseElem) * value
      setremElem(result)
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Pixel To REM Conversion"
        description="Pixel And REM Units conversion, Simple input root size, and one input of (pixels or REM) to get a result. The conversion is based on the default root font-size of 16 pixels you can change the default root font-size, converts pixels to rem, and rem to pixels."
        keywords={[
          "px to rem, root 16 px rem, rem to px, make root px rem, font size root px,font size px to rem, any root px to rem, pixel to REM, pixels to css rem, font size converter.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Pixel To REM Conversion</Title>
        <FlexDiv maxWidth="500px">
          <Field>
            <Control>
              <StaticButton>Root size is</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                type="number"
                placeholder="Base pixel size (eg: 16px)"
                value={baseElem}
                onChange={baseElemC}
              />
            </Control>
            <Control>
              <StaticButton
                bottomLeftRadius="0"
                TopLeftRadius="0"
                bottomRightRadius="4px"
                topRightRadius="4px"
              >
                px
              </StaticButton>
            </Control>
          </Field>
          <p>I want my text to be</p>
          <Field>
            <Control>
              <StaticButton>Pixel</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                type="number"
                placeholder="px (eg: 32px)"
                value={pxElem}
                onChange={calculatePx}
              />
            </Control>
            <Control>
              <StaticButton
                bottomLeftRadius="0"
                TopLeftRadius="0"
                bottomRightRadius="4px"
                topRightRadius="4px"
              >
                px
              </StaticButton>
            </Control>
          </Field>

          <Field>
            <Control>
              <StaticButton>REM</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                type="number"
                placeholder="rem (eg: 2rem)"
                value={remElem}
                onChange={calculateRem}
              />
            </Control>
            <Control>
              <StaticButton
                bottomLeftRadius="0"
                TopLeftRadius="0"
                bottomRightRadius="4px"
                topRightRadius="4px"
              >
                rem
              </StaticButton>
            </Control>
          </Field>
        </FlexDiv>
        <br />

        <h3> Pixels And REM</h3>
        <p>
          <strong>
            Convert pixels to the CSS unit REM or converts REM to pixels. The
            conversion is based on the default font-size of 16 pixels but can be
            changed in the input above.
          </strong>
          <br />
        </p>

        <br />
        <h5>
          Pixels to REM And REM to Pixels Conversion (root font-size is 16px):
        </h5>
        <br />
        <Columns>
          <Column>
            <Table>
              <thead>
                <tr>
                  <th>Pixels</th>
                  <th>REM</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1px</td>
                  <td>0.06rem</td>
                </tr>
                <tr>
                  <td>2px</td>
                  <td>0.13rem</td>
                </tr>
                <tr>
                  <td>3px</td>
                  <td>0.19rem</td>
                </tr>
                <tr>
                  <td>4px</td>
                  <td>0.3rem</td>
                </tr>
                <tr>
                  <td>5px</td>
                  <td>0.3rem</td>
                </tr>
                <tr>
                  <td>6px</td>
                  <td>0.4rem</td>
                </tr>
                <tr>
                  <td>8px</td>
                  <td>0.5rem</td>
                </tr>
                <tr>
                  <td>10px</td>
                  <td>0.6rem</td>
                </tr>
                <tr>
                  <td>12px</td>
                  <td>0.8rem</td>
                </tr>
                <tr>
                  <td>14px</td>
                  <td>0.9rem</td>
                </tr>
                <tr>
                  <td>15px</td>
                  <td>0.9rem</td>
                </tr>
                <tr>
                  <td>16px</td>
                  <td>1rem</td>
                </tr>
                <tr>
                  <td>18px</td>
                  <td>1.1rem</td>
                </tr>
                <tr>
                  <td>20px</td>
                  <td>1.3rem</td>
                </tr>
                <tr>
                  <td>24px</td>
                  <td>1.5rem</td>
                </tr>
                <tr>
                  <td>25px</td>
                  <td>1.6rem</td>
                </tr>
                <tr>
                  <td>28px</td>
                  <td>1.8rem</td>
                </tr>
                <tr>
                  <td>32px</td>
                  <td>2rem</td>
                </tr>
                <tr>
                  <td>36px</td>
                  <td>2rem</td>
                </tr>
                <tr>
                  <td>40px</td>
                  <td>3rem</td>
                </tr>
                <tr>
                  <td>44px</td>
                  <td>3rem</td>
                </tr>
                <tr>
                  <td>48px</td>
                  <td>3rem</td>
                </tr>
                <tr>
                  <td>50px</td>
                  <td>3rem</td>
                </tr>
                <tr>
                  <td>56px</td>
                  <td>4rem</td>
                </tr>
                <tr>
                  <td>64px</td>
                  <td>4rem</td>
                </tr>
                <tr>
                  <td>72px</td>
                  <td>5rem</td>
                </tr>
                <tr>
                  <td>75px</td>
                  <td>5rem</td>
                </tr>
                <tr>
                  <td>80px</td>
                  <td>5rem</td>
                </tr>
                <tr>
                  <td>90px</td>
                  <td>6rem</td>
                </tr>
                <tr>
                  <td>100px</td>
                  <td>6rem</td>
                </tr>
              </tbody>
            </Table>
          </Column>
          <Column>
            <Table>
              <thead>
                <tr>
                  <th>REM</th>
                  <th>Pixels</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0.01rem</td>
                  <td>0.16px</td>
                </tr>
                <tr>
                  <td>0.03rem</td>
                  <td>0.5px</td>
                </tr>
                <tr>
                  <td>0.05rem</td>
                  <td>0.8px</td>
                </tr>
                <tr>
                  <td>0.08rem</td>
                  <td>1.3px</td>
                </tr>
                <tr>
                  <td>0.1rem</td>
                  <td>1.6px</td>
                </tr>
                <tr>
                  <td>0.15rem</td>
                  <td>2px</td>
                </tr>
                <tr>
                  <td>0.2rem</td>
                  <td>3px</td>
                </tr>
                <tr>
                  <td>0.5rem</td>
                  <td>8px</td>
                </tr>
                <tr>
                  <td>1rem</td>
                  <td>16px</td>
                </tr>
                <tr>
                  <td>2rem</td>
                  <td>32px</td>
                </tr>
                <tr>
                  <td>3rem</td>
                  <td>48px</td>
                </tr>
                <tr>
                  <td>4rem</td>
                  <td>64px</td>
                </tr>
                <tr>
                  <td>5rem</td>
                  <td>80px</td>
                </tr>
                <tr>
                  <td>6rem</td>
                  <td>96px</td>
                </tr>
                <tr>
                  <td>8rem</td>
                  <td>128px</td>
                </tr>
                <tr>
                  <td>10rem</td>
                  <td>160px</td>
                </tr>
                <tr>
                  <td>15rem</td>
                  <td>240px</td>
                </tr>
                <tr>
                  <td>20rem</td>
                  <td>320px</td>
                </tr>
                <tr>
                  <td>30rem</td>
                  <td>480px</td>
                </tr>
                <tr>
                  <td>40rem</td>
                  <td>640px</td>
                </tr>
                <tr>
                  <td>50rem</td>
                  <td>800px</td>
                </tr>
                <tr>
                  <td>60rem</td>
                  <td>960px</td>
                </tr>
                <tr>
                  <td>80rem</td>
                  <td>1280px</td>
                </tr>
                <tr>
                  <td>100rem</td>
                  <td>1600px</td>
                </tr>
              </tbody>
            </Table>
          </Column>
        </Columns>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
      </div>
    </Layout>
  )
}

export default PixelAndREMConverter
